import './pages-css/04Gemstones.css'

const Gemstones = () => {
    return (
        <main className='gemstones-guide-general-container'>
            <h1 className='gemstones-guide-main-title'>GEMSTONES DETAILS AND SECRETS</h1>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>TOURMALINE</h2>
                <div className='gemstone1-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Tourmaline is known for absorbing negative energy, promoting positivity and self-confidence, and helping wearers speak their truth. It is October's birthstone and also called the "stone of reconciliation."⁠
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>ANDALUSITE</h2>
                <div className='gemstone2-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Andalusite is the seeing stone that promotes the desire for self-realization, helping one to rebalance and re-align. This stone helps the wearer discover problems and emotional blockages while pointing them to the possible resolution. Lastly, it is associated with August.⁠
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>OLIVINE</h2>
                <div className='gemstone3-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Olivine has a positive effect on mental health, promoting self-confidence and reducing stress. It is associated with spirituality and mystical powers, and is traditionally connected with the month of August.
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>AGATE</h2>
                <div className='gemstone4-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Agate is a gemstone that balances the mind, body, and spirit. It transforms negative energy and enhances mental function, concentration, perception, and analytical abilities. It is also associated with May.
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>GARNET</h2>
                <div className='gemstone5-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Garnets are widely known for inducing passion and energy in the wearer. It can also bring good luck in love and relationships as well as protect the wearer from negative and evil thoughts. It is the birthstone of January, connecting those born in that month with the gem.⁠
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>LAPIS LAZULI</h2>
                <div className='gemstone6-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Lapis Lazuli is a well-known gemstone believed to enhance spirituality, self-awareness, and inner truth. It is also associated with increased creativity and emotional expression, and is the birthstone for December.
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>FELDSPAR</h2>
                <div className='gemstone7-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Feldspars can help achieve goals by increasing self-awareness, creativity, self-respect, and self-esteem, making it a great gem for self-love. It is also considered to be the birthstone of June.
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>ZIRCON</h2>
                <div className='gemstone8-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Zircon is well known to augment the physical and mental strength of its wearer, helping as well in meditation. It also helps in bringing visions and dreams to reality and provides prosperity to the family of the wearer. Lastly, it is the birthstone of December.⁠
                </p>
            </section>
            <section className='gemstone-container'>
                <h2 className='gemstone-title'>DIAMOND</h2>
                <div className='gemstone9-image gemstone-image'></div>
                <p className='gemstone-text'>
                    Diamonds boost confidence and positivity as well as offer good physical health. Moreover, it is said that they can bring wealth to the bearer. Lastly, they are tied to April.
                </p>
            </section>
        </main>
    );
}

export default Gemstones