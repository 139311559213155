import './specific-gemstone.css'

const Tourmaline = () => {
    return (
        <div className="tourmaline">
            <div className='tourmaline-title'>TOURMALINE</div>
            <div className="tourmaline-img"></div>
            <div className='tourmaline-text'>Tourmaline is known for absorbing negative energy, promoting positivity and self-confidence, and helping wearers speak their truth. It is October's birthstone and also called the "stone of reconciliation."⁠</div>
        </div>
    );
}

export default Tourmaline;
