import './pages-css/01Home.css'

const Home = () => {
    return (
        <main className="home-general-container">
            <h1 className="home1-main-title">WELCOME TO THE JOURNEY</h1>
            <section className="home2-container">
                <h2 className="home2-title">IN THE ASHES OF FORGIVENESS</h2>
                <p className="home2-text">
                    A vindictive woman and a power-hungry mercenary meet, and the sudden disappearance of a princess will lead them into a direct
                    confrontation with otherworldly creatures—and their suppressed emotions. A well-executed search plan, their survival instincts,
                    and their growing love are their only guiding lights.
                </p>
                <a className='home2-button' href="/book">Read more about The Book ➤</a>
            </section>
            <div className="home3-container"></div>
            <div className="home4-container"></div>
            <section className="home5-container">
                <h2 className="home5-title">HARRYS STRATIGAKIS</h2>
                <p className='home5-text'>
                    Welcome to the land where fantasy and psychology blend in. My name is Harrys Stratigakis,
                    an aspiring fantasy author that desires to pass his ideas and life’s work to everyone that would
                    like to be a part of it. So, what are you waiting for? Scroll through this page, click on anything that
                    interests you (even the gemstones lying around on it), and let’s embark together on this wonderful journey!
                </p>
                <a className='home5-button' href="/about">Read more about Harrys ➤</a>
            </section>
            <section className="home6-container">
                <h2 className="home6-title">WOULD YOU LIKE TO LEARN MORE ABOUT GEMSTONES?</h2>
                <p className="home6-text">Find one on the homepage and click on it to obtain specific info</p>
                <p className="home6-text">OR</p>
                <a className='home6-button' href="/gemstones-guide">Read more about gems ➤</a>
            </section>
            <a className="gemstone1" href="/Tourmaline" target="_blank"><div></div></a>
            <a className="gemstone2" href="/Andalusite" target="_blank"><div></div></a>
            <a className="gemstone3" href="/Olivine" target="_blank"><div></div></a>
            <a className="gemstone4" href="/Agate" target="_blank"><div></div></a>
            <a className="gemstone5" href="/Garnet" target="_blank"><div></div></a>
            <a className="gemstone6" href="/Lapis" target="_blank"><div></div></a>
            <a className="gemstone7" href="/Feldspar" target="_blank"><div></div></a>
            <a className="gemstone8" href="/Zircon" target="_blank"><div></div></a>
            <a className="gemstone9" href="/Diamond" target="_blank"><div></div></a>
        </main>
    );
}

export default Home