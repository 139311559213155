const Zircon = () => {
    return ( 
        <div className='zircon'>
            <div className='zircon-title'>ZIRCON</div>
            <div className="zircon-img"></div>
            <div className='zircon-text'>Zircon is well known to augment the physical and mental strength of its wearer, helping as well in meditation. It also helps in bringing visions and dreams to reality and provides prosperity to the family of the wearer. Lastly, it is the birthstone of December.⁠</div>
        </div>
     );
}
 
export default Zircon;