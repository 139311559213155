const Agate = () => {
    return ( 
        <div className='agate'>
            <div className='agate-title'>AGATE</div>
            <div className="agate-img"></div>
            <div className='agate-text'>Agate is a gemstone that balances the mind, body, and spirit. It transforms negative energy and enhances mental function, concentration, perception, and analytical abilities. It is also associated with May.</div>
        </div>
     );
}
 
export default Agate;