import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import ScrollToTop from './components/Scroll'
import Home from './pages/01Home'
import Book from './pages/02Book'
import Sidestory from './pages/03Sidestory'
import Gemstones from './pages/04Gemstones'
import About from './pages/05About'
import { Tourmaline, Andalusite, Olivine, Agate, Garnet, Lapis, Feldspar, Zircon, Diamond } from './pages/specific-gemstone'
import ReadingRoom from './pages/10ReadingRoom'
import { BsFillSunFill, BsFillMoonStarsFill } from 'react-icons/bs'

function App() {
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  useEffect(() => {
    localStorage.setItem('theme', theme)
  }, [theme])

  const changeTheme = () => {
    setTheme(prevTheme => (prevTheme === 'light' ? 'dark' : 'light'))
  }

  return (
    <Router>
      <div className="App" data-theme={theme}>
        <div className='top-empty-space'></div>
        <button className={theme === 'light' ? 'theme-changer-button-light' : 'theme-changer-button-dark'} onClick={changeTheme}>{theme === 'light' ? <BsFillMoonStarsFill /> : <BsFillSunFill />}</button>
        <Navbar />
        <div className="content">
          <Routes>
            {/* <Route exact path="/" element={<Layout />} /> */}
            <Route exact path="/" element={<Home />} />
            <Route exact path="/book" element={<Book />} />
            <Route exact path="/sidestory" element={<Sidestory />} />
            <Route exact path="/gemstones-guide" element={<Gemstones />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/api/sidestories/:id" element={<ReadingRoom />} />
            <Route exact path="/Tourmaline" element={<Tourmaline />} />
            <Route exact path="/Andalusite" element={<Andalusite />} />
            <Route exact path="/Olivine" element={<Olivine />} />
            <Route exact path="/Agate" element={<Agate />} />
            <Route exact path="/Garnet" element={<Garnet />} />
            <Route exact path="/Lapis" element={<Lapis />} />
            <Route exact path="/Feldspar" element={<Feldspar />} />
            <Route exact path="/Zircon" element={<Zircon />} />
            <Route exact path="/Diamond" element={<Diamond />} />
          </Routes>
          <ScrollToTop />
        </div>
        <Footer />
      </div>
    </Router>
  )
}

export default App