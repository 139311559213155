import axios from '../api/axios'
// import * as sidestoryAPI from '../api/sidestory'
import { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import './pages-css/10Reading-Room.css'

const ReadingRoom = () => {
    let { id } = useParams()

    const [sidestoryToRead, setSidestoryToRead] = useState(null)
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [load, setLoad] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        if (hasLoaded) {
            const startLoadingTime = performance.now()

            axios.get(`/api/sidestories/${id}`)
                .then((response) => {
                    setSidestoryToRead(response.data)

                    const endLoadingTime = performance.now()
                    const loadingTime = endLoadingTime - startLoadingTime

                    let animationStart
                    function animateLoading(timestamp) {
                        if (!animationStart) animationStart = timestamp
                        const progress = Math.min((timestamp - animationStart) / loadingTime, 1)
                        setLoadingProgress(Math.floor(progress * 100))

                        const loadingBar = document.querySelector('.reading-room-loading-bar');
                        if (loadingBar) {
                            loadingBar.style.width = progress * 100 + '%';
                        }

                        if (progress < 1) {
                            requestAnimationFrame(animateLoading)
                        } else {
                            setLoad(false)
                        }
                    }

                    requestAnimationFrame(animateLoading)
                })
                .catch(e => console.log(e))
        } else {
            setHasLoaded(true)
        }
    }, [hasLoaded, id])

    return (
        <main className='reading-room-general-container'>
            {load && (
                <>
                    <div className='reading-room-loading'>
                        <span>L</span>&nbsp;
                        <span>O</span>&nbsp;
                        <span>A</span>&nbsp;
                        <span>D</span>&nbsp;
                        <span>I</span>&nbsp;
                        <span>N</span>&nbsp;
                        <span>G</span>&nbsp;
                        <span>.</span>&nbsp;
                        <span>.</span>&nbsp;
                        <span>.</span>&nbsp;
                    </div>
                    <div className='reading-room-loading-percentage'>{loadingProgress}%</div>
                    <div className='reading-room-loading-bar-container'>
                        <div className='reading-room-loading-bar'></div>
                    </div>
                </>
            )}
            {!load && (
                <>
                    <h1 className='reading-room-title'>{sidestoryToRead.sidestory_title}</h1>
                    <p dangerouslySetInnerHTML={{ __html: sidestoryToRead.sidestory_text.replaceAll('\n', '</br>').replaceAll('\t', '&nbsp &nbsp &nbsp &nbsp') }} className='reading-room-text'>
                    </p>
                </>
            )}
        </main>
    )
}

export default ReadingRoom