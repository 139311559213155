const Andalusite = () => {
    return ( 
        <div className='andalusite'>
            <div className='andalusite-title'>ANDALUSITE</div>
            <div className="andalusite-img"></div>
            <div className='andalusite-text'>Andalusite is the seeing stone that promotes the desire for self-realization, helping one to rebalance and re-align. This stone helps the wearer discover problems and emotional blockages while pointing them to the possible resolution. Lastly, it is associated with August.⁠</div>
        </div>
     );
}
 
export default Andalusite;