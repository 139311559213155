// import CountdownTimer from "../components/CountdownTimer";
import './pages-css/02Book.css'

const Book = () => {

    return (
        <main className="book-general-container">
            <h1 className='book1-main-title'>IN THE ASHES OF FORGIVENESS</h1>
            <section className='book2-left-container'>
                <h2 className='book-title'>Book 1 Blurb - V1</h2>
                <p className='book-text'>
                    {/* Our souls are instruments of fate…
                    <br />
                    Stirred by its invisible hand to paths predetermined.
                    <br />
                    <br /> */}
                    Zan Metris wants power so he can rise to the top and build his own legacy, different from his father’s selfishly made one.
                    To accomplish that, he works as a mercenary to fight the otherworldly beings called Dwellers until, one day, he meets Auriel
                    Reid on one of his routine missions.
                    <br />
                    <br />
                    However, the Dwellers can’t wait to have the world of Arcadion to be theirs to rule. They take hostage Rosa, Auriel’s closest
                    friend, assuming she knows where a legendary Rune Inscription—said to give immense powers to its users—is hidden because of
                    her royal bloodline. Afraid of losing another person dear to her, Auriel begins her search for Rosa, and Zan commits to
                    helping her to demonstrate his merit.
                    <br />
                    <br />
                    During their pursuit, they realize that the Dwellers aren’t the only enemies they have to face—their deepest fears being
                    another thing impending their goal. With Auriel worried about her past repeating itself and Zan not being powerful enough
                    to slay his foes and prove himself worthy, their growing love for each other might give them the strength they need to
                    come out victorious in their struggles.
                    <br />
                    <br />
                    With inner demons to conquer and mighty creatures to battle, failure is not an option. Or else the whole world will pay the price.
                </p>
            </section>
            <div className='book3-right-container-image'>
                {/* <div className='book-image-title book-page-glow-title'>IN THE ASHES OF FORGIVENESS</div> */}
                {/* <div className='book-image-questionmark book-page-glow-questionmark'>?</div> */}
                {/* <div className='book-image-countdown book-page-glow-countdown'><CountdownTimer /></div> */}
            </div>
        </main>
    );
}

export default Book