const Lapis = () => {
    return ( 
        <div className='lapis'>
            <div className='lapis-title'>LAPIS LAZULI</div>
            <div className="lapis-img"></div>
            <div className='lapis-text'>Lapis Lazuli is a well-known gemstone believed to enhance spirituality, self-awareness, and inner truth. It is also associated with increased creativity and emotional expression, and is the birthstone for December.</div> 
        </div>
     );
}
 
export default Lapis;