const Diamond = () => {
    return ( 
        <div className='diamond'>
            <div className='diamond-title'>DIAMOND</div>
            <div className="diamond-img"></div>
            <div className='diamond-text'>Diamonds boost confidence and positivity as well as offer good physical health. Moreover, it is said that they can bring wealth to the bearer. Lastly, they are tied to April.</div>
        </div>
     );
}
 
export default Diamond;