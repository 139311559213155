const Feldspar = () => {
    return ( 
        <div className='feldspar'>
            <div className='feldspar-title'>FELDSPAR</div>
            <div className="feldspar-img"></div>
            <div className='feldspar-text'>Feldspars can help achieve goals by increasing self-awareness, creativity, self-respect, and self-esteem, making it a great gem for self-love. It is also considered to be the birthstone of June.</div>
        </div>
     );
}
 
export default Feldspar;