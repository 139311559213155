import React, { useState, useEffect } from 'react';
import { BsArrowUp } from "react-icons/bs";
import './Scroll.css'

// RxDoubleArrowUp
// TbArrowBigUpLines
// BsArrowUp

const ScrollToTop = () => {
    const [, setShowTopBtn] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
    }, []);
    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <div className='footer-scroll-to-top-container'>
            <div onClick={goToTop} className='footer-scroll-to-top'><BsArrowUp /></div>
        </div>
    );
}


export default ScrollToTop