import { FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri"
import { SiMedium, SiKofi } from "react-icons/si"
import axios from '../api/axios'
import { useState } from "react";
import { usernameValidation } from '../validations/usernameValidation'
import './Footer.css'

const Footer = () => {
    const dateJoinned = new Date()

    const [username, setUsername] = useState()
    const [email, setEmail] = useState()
    const [joinDate, setJoinDate] = useState('')
    const [errMsg, setErrMsg] = useState('')

    const createUser = async (e) => {
        e.preventDefault()
        let formData = {
            username: e.target[0].value
        }
        const isValid = await usernameValidation.isValid(formData)
        if (!isValid) {
            setErrMsg("Username first character has to be a Latin letter (a-Z) or a number.")
            return
        }
        axios.post('/api/users', {
            username: username,
            email: email,
            joinDate: joinDate
        }).then((response) => {
            setErrMsg(response.data.msg)
            console.log(response.data)
        })
    }

    return (

        <div className='footer-image'>
            <div className="inner-footer-container">
                <section className='footer-left-newsletter-wrapper'>
                    <h2 className='footer-newsletter-title'>NEWSLETTER</h2>
                    <p className="footer-newsletter-text">Sign up to my newsletter to receive personalized info about the book.</p>
                    <form onSubmit={createUser}>
                        <input
                            onChange={(e) => { setUsername(e.target.value) }}
                            onKeyDown={() => { setErrMsg('') }}
                            placeholder="*Username"
                            type="text"
                            className="footer-input-newsletter-username" />
                        <input
                            onChange={(e) => { setEmail(e.target.value) }}
                            onKeyDown={() => { setErrMsg('') }}
                            placeholder="*Email"
                            type="text"
                            className="footer-input-newsletter-email" />
                        <input value='Submit' type='submit' onMouseDown={() => { setJoinDate(dateJoinned) }} className='newsletter-sign-up-button'></input>
                        <div className={!errMsg ? 'newsletter-error-message-hidden' : 'newsletter-error-message'}>  {errMsg}</div>
                    </form>
                </section>
                <section className='footer-mid-rights-wrapper'>
                    <div className='footer-author-socials'>
                        <a href="https://www.instagram.com/harrys.stratigakis/" rel="noreferrer" target="_blank"><RiInstagramFill size={22} className='author-insta' /></a>
                        <a href="https://twitter.com/Harrys65746763" rel="noreferrer" target="_blank"><FaTwitter size={22} className='author-twitter' /></a>
                        <a href="https://harrys-stratigakis.medium.com/" rel="noreferrer" target="_blank"><SiMedium size={22} /></a>
                        <a href="https://ko-fi.com/harrys_stratigakis" rel="noreferrer" target="_blank"><SiKofi size={22} /></a>
                    </div>
                    <p className='footer-mid-rights'>© Harrys Stratigakis | @2022-2023 Harrys Stratigakis. All Rights Reserved.</p>
                </section>
                <section className='footer-right-contact-wrapper'>
                    <h2 className='footer-connect-title'>CONNECT WITH US</h2>
                    <div className="footer-author-container">
                        <h3 className='footer-author-name'>Harrys Stratigakis</h3>
                        <a className="footer-author-email" href="https://gmail.com" rel="noreferrer" target="_blank">hstratigakis@gmail.com</a>
                    </div>
                    <div className="footer-webdev-container">
                        <h3 className='footer-webdev-name'>Web Dev - George P.</h3>
                        <div className='footer-webdev-email'><a href="https://gmail.com" rel="noreferrer" target="_blank">giorgosppro@gmail.com</a></div>
                        <div className='footer-webdev-socials'>Instagram: <a href="https://www.instagram.com/georgem_ad/" rel="noreferrer" target="_blank"><RiInstagramFill size={22} className='webdev-insta' /></a></div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Footer