const Olivine = () => {
    return ( 
        <div className='olivine'>
            <div className='olivine-title'>OLIVINE</div>
            <div className="olivine-img"></div>
            <div className='olivine-text'>Olivine has a positive effect on mental health, promoting self-confidence and reducing stress. It is associated with spirituality and mystical powers, and is traditionally connected with the month of August.</div>
        </div>
     );
}
 
export default Olivine;