import axios from '../api/axios'
import { useEffect, useState } from "react"
import './pages-css/03Sidestory.css'

const Sidestory = () => {
    const [sidestoryList, setSidestoryList] = useState()
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [load, setLoad] = useState(true)

    const [hasLoaded, setHasLoaded] = useState(false)

    useEffect(() => {
        if (hasLoaded) {
            const startLoadingTime = performance.now()

            axios.get('/api/sidestories')
                .then((response) => {
                    setSidestoryList(response.data)

                    const endLoadingTime = performance.now()
                    const loadingTime = endLoadingTime - startLoadingTime

                    let animationStart
                    function animateLoading(timestamp) {
                        if (!animationStart) animationStart = timestamp
                        const progress = Math.min((timestamp - animationStart) / loadingTime, 1)
                        setLoadingProgress(Math.floor(progress * 100))

                        const loadingBar = document.querySelector('.sidestory-page-loading-bar');
                        if (loadingBar) {
                            loadingBar.style.width = progress * 100 + '%';
                        }

                        if (progress < 1) {
                            requestAnimationFrame(animateLoading)
                        } else {
                            setLoad(false)
                        }
                    }
                    requestAnimationFrame(animateLoading)
                })
                .catch((e) => {
                    console.log(e)
                })
        } else {
            setHasLoaded(true)
        }
    }, [hasLoaded])

    return (
        <main className='sidestories-general-container'>
            {load && (
                <>
                    <div className='sidestory-page-loading'>
                        <span>L</span>&nbsp;
                        <span>O</span>&nbsp;
                        <span>A</span>&nbsp;
                        <span>D</span>&nbsp;
                        <span>I</span>&nbsp;
                        <span>N</span>&nbsp;
                        <span>G</span>&nbsp;
                        <span>.</span>&nbsp;
                        <span>.</span>&nbsp;
                        <span>.</span>&nbsp;
                    </div>
                    <div className='sidestory-page-loading-percentage'>{loadingProgress}%</div>
                    <div className='sidestory-page-loading-bar-container'>
                        <div className='sidestory-page-loading-bar'></div>
                    </div>
                </>
            )}
            {!load && (
                <>
                    <h1 className='sidestories-main-title'>CHOOSE A STORY</h1>
                    <div className='all-sidestories-wrapper'>
                        {sidestoryList?.map((val) => {
                            return (
                                <div className='single-sidestory-wrapper' key={val._id}>
                                    <h2 className='sidestory-title'>{val.sidestory_title}</h2>
                                    <p className='sidestory-brief'>{val.sidestory_brief}</p>
                                    <a className='sidestory-button' href={`/api/sidestories/${val._id}`} rel="noreferrer">Read the Full Sidestory ➤</a>
                                </div>
                            )
                        })}
                    </div>
                </>
            )}
        </main>
    )
}

export default Sidestory
