import { useState, useEffect, useRef } from 'react'
import { FaTwitter } from "react-icons/fa"
import { RiInstagramFill } from "react-icons/ri"
import { SiMedium, SiKofi } from "react-icons/si"
import { BsArrowUp, BsArrowDown } from 'react-icons/bs'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoIosHome } from 'react-icons/io'
import './Navbar.css'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
    const [displayNavbar, setDisplayNavbar] = useState(true)
    const [width, setWidth] = useState(window.innerWidth)
    const [displayDropdown, setDisplayDropdown] = useState(false)
    const dropdownRef = useRef(null)


    function showNavbar() {
        if (displayNavbar === true) {
            setDisplayNavbar(false)
        }
        else {
            setDisplayNavbar(true)
        }
    }

    // when screen width drops below 640px, replace right-navbar-links with a dropdown menu.
    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [width]);

    // when someone clicks anywhere on the screen, if the dropdown menu is already displayed, hide it.
    useEffect(() => {
        function handleClickOutside(e) {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setDisplayDropdown(false)
            }
        }
        if (displayDropdown) {
            window.addEventListener('click', handleClickOutside)
        }

        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [displayDropdown])

    // when the dropdown button is clicked, display the dropdown links. Else if they are already displayed, hide them.
    function dropdownTrigger() {
        if (displayDropdown === false) {
            setDisplayDropdown(true)
        } else {
            setDisplayDropdown(false)
        }
        // document.getElementsByClassName("navbar-dropdown-menu").style.transitionDuration = "all 2s";
    }

    return (
        <header>
            <div className={displayNavbar ? 'hidden-down-arrow' : 'show-navbar-down-arrow'} onClick={() => showNavbar()}><BsArrowDown /></div>
            <nav className={displayNavbar ? "navbar-general-container" : 'hidden-navbar-general-container'}>
                {width > 650 ? (
                    <>
                        <section className='navbar_fullname_and_socials'>
                            <h1 className="navbar-fullname">Harrys Stratigakis</h1>
                            <a href="https://www.instagram.com/harrys.stratigakis/" rel="noreferrer" target="_blank"><RiInstagramFill /></a>
                            <a href="https://twitter.com/Harrys65746763" rel="noreferrer" target="_blank"><FaTwitter /></a>
                            <a href="https://harrys-stratigakis.medium.com/" rel="noreferrer" target="_blank"><SiMedium /></a>
                            <a href="https://ko-fi.com/harrys_stratigakis" rel="noreferrer" target="_blank"><SiKofi /></a>
                        </section>
                        <section className="navbar-links-container">
                            <NavLink to="/" className={({ isActive }) => { return (isActive ? 'glow' : '') }}>Home</NavLink>
                            <NavLink to="/book" className={({ isActive }) => { return (isActive ? 'glow' : '') }}>Book</NavLink>
                            <NavLink to="/sidestory" className={({ isActive }) => { return (isActive ? 'glow' : '') }}>Stories</NavLink>
                            <NavLink to="/gemstones-guide" className={({ isActive }) => { return (isActive ? 'glow' : '') }}>Gemstones</NavLink>
                            <NavLink to="/about" className={({ isActive }) => { return (isActive ? 'glow' : '') }}>About</NavLink>
                            <div className={displayNavbar ? 'hide-navbar-up-arrow' : 'hidden-up-arrow'} onClick={() => showNavbar()}><BsArrowUp /></div>
                        </section>
                    </>
                ) : (
                    <>
                        <section className='navbar_fullname_and_socials'>
                            <NavLink to="/"><IoIosHome /></NavLink>
                            <h1 className="navbar-fullname">Harrys Stratigakis</h1>
                            <a href="https://www.instagram.com/harrys.stratigakis/" rel="noreferrer" target="_blank"><RiInstagramFill /></a>
                            <a href="https://twitter.com/Harrys65746763" rel="noreferrer" target="_blank"><FaTwitter /></a>
                            <a href="https://harrys-stratigakis.medium.com/" rel="noreferrer" target="_blank"><SiMedium /></a>
                            <a href="https://ko-fi.com/harrys_stratigakis" rel="noreferrer" target="_blank"><SiKofi /></a>
                        </section>
                        <div className='navbar-dropdown-container' data-navbar-dropdown-container ref={dropdownRef}>
                            {!displayDropdown ? (
                                <>
                                    <button onClick={dropdownTrigger} className='navbar-dropdown-button' data-navbar-dropdown-button><AiOutlineMenu /></button>
                                    <div className='hidden-navbar-dropdown-menu'>
                                        <NavLink to="/" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Home</NavLink>
                                        <NavLink to="/book" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Book</NavLink>
                                        <NavLink to="/sidestory" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Sidestories</NavLink>
                                        <NavLink to="/about" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>About</NavLink>
                                        <NavLink to="/gemstones-guide" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Gemstones</NavLink>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <button onClick={dropdownTrigger} className='navbar-dropdown-button' data-navbar-dropdown-button><AiOutlineMenu /></button>
                                    <div className='navbar-dropdown-menu'>
                                        <NavLink to="/" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Home</NavLink>
                                        <NavLink to="/book" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Book</NavLink>
                                        <NavLink to="/sidestory" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Sidestories</NavLink>
                                        <NavLink to="/about" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>About</NavLink>
                                        <NavLink to="/gemstones-guide" className={({ isActive }) => { return (isActive ? 'glow dropdown-links' : 'dropdown-links') }}>Gemstones</NavLink>
                                    </div>
                                </>
                            )
                            }
                        </div>
                    </>
                )}
            </nav >
        </header >
    )
}

export default Navbar