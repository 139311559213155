import './pages-css/05About.css'

const About = () => {
    return (
        <main className="about-general-container">
            <h1 className='about-main-title'>WHO IS HARRYS?</h1>
            <div className='about-image'></div>
            <section className='about-right-container'>
                <h2 className='about-title'>LETS GET TO KNOW HARRYS</h2>
                <p className='about-text'>
                    &nbsp; &nbsp; &nbsp; Harrys Stratigakis is an aspiring fantasy author, striving to write his first series of high-fantasy books titled “In the Ashes of Forgiveness”.
                    From the early stages of his life, he was constantly trying to quell his vivid imaginative mind. Somewhere along the way, though, this dream faded away in the bustling rhythms of everyday life.
                    <br />
                    &nbsp; &nbsp; &nbsp;
                    Around 25 years of age, and after having studied psychology deeply, he started writing psychology articles on the Medium platform. These articles were aimed at people that lacked self-esteem and were meant to provide solid reasoning and advice about how to regain power over one’s life.
                    <br />
                    &nbsp; &nbsp; &nbsp;
                    However, after indulging in this activity for two years straight, he felt like his psyche was drained, even though his creative nature was still at its peak. He couldn’t stop writing, feeling that this was the one and true passion of his life, but he also couldn’t find the power to continue finding the right subject and covering it as faithfully as possible.
                    <br />
                    &nbsp; &nbsp; &nbsp;
                    At that time it occurred to him. Why not create a work entirely of his own mind? A world where he could unleash every bit of his thoughts and provide a deeper psychological view of the characters that lived through it? And thus, Arcadion was created, and the journey to its beautiful but harsh lands as well as the pursuit of sharing this creation of his began.
                </p>
            </section>
        </main>
    );
}

export default About