const Garnet = () => {
    return ( 
        <div className='garnet'>
            <div className='garnet-title'>GARNET</div>
            <div className="garnet-img"></div>
            <div className='garnet-text'>Garnets are widely known for inducing passion and energy in the wearer. It can also bring good luck in love and relationships as well as protect the wearer from negative and evil thoughts. It is the birthstone of January, connecting those born in that month with the gem.⁠</div>
        </div>
     );
}
 
export default Garnet;